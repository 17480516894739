import { defineStore } from 'pinia';
import type { PackagingAttributes } from '~/types';

interface State {
    /**
     * Globally available ingredients.
     */
    packagings: PackagingAttributes[];
}

const usePackagingStore = defineStore('packaging', {
    state: (): State => {
        return {
            packagings: []
        };
    }
});

export default usePackagingStore;
